<template>
  <transition
    :enter-from-class="`transform ${fromScale} opacity-0`"
    :enter-to-class="`transform ${toScale} opacity-100`"
    :leave-from-class="`transform ${toScale} opacity-100`"
    :leave-to-class="`transform ${fromScale} opacity-0`"
    :enter-active-class="`transition duration-${duration}`"
    :leave-active-class="`transition duration-${duration}`"
    appear
    @before-leave="$emit('before-leave')"
    @after-leave="$emit('after-leave')"
    @after-enter="$emit('after-enter')"
  >
    <slot></slot>
  </transition>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: "out-in",
    },
    duration: {
      type: [String, Number],
      default: 150,
    },
    fromScale: {
      type: String,
      default: "scale-90",
    },
    toScale: {
      type: String,
      default: "scale-100",
    },
  },
}
</script>
